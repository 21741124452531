var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: {
                  "head-title": "费用基础信息",
                  "head-btn-options": _vm.headTopBtnOptions,
                },
                on: {
                  "head-cancel": _vm.handleHeadGoback,
                  "head-save": function ($event) {
                    return _vm.headSave(1)
                  },
                  "head-save-back": function ($event) {
                    return _vm.headSave(2)
                  },
                },
              }),
              _c("form-layout", {
                ref: "formLayout",
                attrs: { column: _vm.formColumn, dataForm: _vm.dataForm },
                on: {
                  "update:dataForm": function ($event) {
                    _vm.dataForm = $event
                  },
                  "update:data-form": function ($event) {
                    _vm.dataForm = $event
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-container",
        [
          _c(
            "el-main",
            [
              _c("uploader", {
                ref: "uploader",
                attrs: {
                  title: "凭证",
                  fileSize: 500,
                  accept:
                    ".jpg,.png,.pdf,.gif,.jpeg,.ppt,.doc,.docx,.xls,.xlsx",
                  multiple: true,
                  fileOptions: _vm.fileOptions,
                  gridRowBtn: _vm.fileGridRowBtn,
                  disabled: _vm.type == "view",
                },
                on: { getTableData: _vm.handleUpload },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.type !== "add"
        ? [
            _c(
              "el-container",
              [
                _c(
                  "el-main",
                  [
                    _c("head-layout", {
                      attrs: {
                        "head-title": "支出明细",
                        "head-btn-options": _vm.lineBtnOptions,
                      },
                      on: { "line-add": _vm.handleLineAdd },
                    }),
                    _c("grid-layout", {
                      ref: "gridLayOut",
                      attrs: {
                        "table-options": _vm.tableOptions,
                        "table-data": _vm.tableData,
                        "table-loading": _vm.tableLoading,
                        "data-total": _vm.page.total,
                        page: _vm.page,
                        editType: _vm.inline,
                        "grid-row-btn": _vm.gridRowBtn,
                      },
                      on: {
                        "page-current-change": _vm.handleCurrentChange,
                        "page-size-change": _vm.handleSizeChange,
                        "page-refresh-change": _vm.onLoad,
                        "grid-row-detail-click": _vm.rowView,
                        "row-edit": _vm.rowEdit,
                        "row-remove": _vm.rowRemove,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "files",
                            fn: function ({ row }) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      row.files
                                        ? JSON.parse(row.files).length
                                        : 0
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2189771771
                      ),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "支出明细",
            visible: _vm.dialogVisible,
            width: "50%",
            top: "8vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "avue-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.visibleLoading,
                  expression: "visibleLoading",
                },
              ],
              ref: "avueLineForm",
              attrs: { option: _vm.formOption },
              on: { submit: _vm.handleSubmit },
              model: {
                value: _vm.lineForm,
                callback: function ($$v) {
                  _vm.lineForm = $$v
                },
                expression: "lineForm",
              },
            },
            [
              _c(
                "template",
                { slot: "subjectId" },
                [
                  _c("el-cascader", {
                    ref: "subjectCascader",
                    staticStyle: { width: "100%" },
                    attrs: {
                      options: _vm.casOptions,
                      placeholder: "请选择费用科目",
                      disabled: _vm.type == "view" || _vm.isFormDisabled,
                      props: {
                        label: "dictValue",
                        value: "dictKey",
                        emitPath: false,
                      },
                      "show-all-levels": false,
                      filterable: "",
                    },
                    on: { change: _vm.handleChange },
                    model: {
                      value: _vm.lineForm.subjectId,
                      callback: function ($$v) {
                        _vm.$set(_vm.lineForm, "subjectId", $$v)
                      },
                      expression: "lineForm.subjectId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "template",
                { slot: "costUserName" },
                [
                  _c("el-input", {
                    ref: "lineUserName",
                    attrs: {
                      disabled: _vm.type == "view" || _vm.isFormDisabled,
                      clearable: "",
                    },
                    on: { clear: _vm.costUserClear, focus: _vm.changeLineUser },
                    model: {
                      value: _vm.lineForm.costUserName,
                      callback: function ($$v) {
                        _vm.$set(_vm.lineForm, "costUserName", $$v)
                      },
                      expression: "lineForm.costUserName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "template",
                { slot: "costOrgName" },
                [
                  _vm.lineForm.costUserId && _vm.lineForm.costUserId !== -1
                    ? _c("avue-input-tree", {
                        attrs: {
                          "default-expand-all": "",
                          disabled: _vm.type == "view" || _vm.isFormDisabled,
                          placeholder: "请选择部门",
                          props: { label: "title", value: "id" },
                          dic: _vm.deptList,
                        },
                        on: { change: _vm.chooseDept },
                        model: {
                          value: _vm.lineForm.costOrgId,
                          callback: function ($$v) {
                            _vm.$set(_vm.lineForm, "costOrgId", $$v)
                          },
                          expression: "lineForm.costOrgId",
                        },
                      })
                    : _c("el-input", {
                        ref: "lineDeptName",
                        attrs: {
                          disabled: _vm.type == "view" || _vm.isFormDisabled,
                        },
                        on: { focus: _vm.changeLineDept },
                        model: {
                          value: _vm.lineForm.costOrgName,
                          callback: function ($$v) {
                            _vm.$set(_vm.lineForm, "costOrgName", $$v)
                          },
                          expression: "lineForm.costOrgName",
                        },
                      }),
                ],
                1
              ),
              _c(
                "template",
                { slot: "files" },
                [
                  _c(
                    "el-upload",
                    {
                      class: {
                        uoloadSty: _vm.showImg,
                        disUoloadSty: _vm.noneUploadFile,
                      },
                      attrs: {
                        disabled: _vm.isFormDisabled,
                        action:
                          "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform",
                        accept:
                          ".jpg,.png,.pdf,.gif,.jpeg,.ppt,.doc,.docx,.xls,.xlsx",
                        limit: 9,
                        "file-list": _vm.fileArray,
                        "on-success": _vm.uploadSuccess,
                        "on-remove": _vm.handleRemove,
                        "on-exceed": _vm.uploadExceed,
                        "on-preview": _vm.handleFilePreview,
                        "before-upload": _vm.handleBeforeUpload,
                      },
                    },
                    [
                      _c("el-button", { attrs: { size: "small" } }, [
                        _c("i", { staticClass: "el-icon-upload2" }),
                        _vm._v(" 上传文件"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          !_vm.isFormDisabled
            ? _c(
                "template",
                { slot: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.dialogLoading,
                          expression: "dialogLoading",
                        },
                      ],
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = false
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) +
                          "\n      "
                      ),
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.dialogLoading,
                          expression: "dialogLoading",
                        },
                      ],
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.handleSave },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) +
                          "\n      "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "人员选择",
            visible: _vm.userShow,
            width: "80%",
            top: "8vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.userShow = $event
            },
          },
        },
        [
          _c("UserDetpDialog", {
            ref: "UserDetpDialog",
            on: { "select-data": _vm.selectUserData },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "部门选择", visible: _vm.deptShow, width: "80%" },
          on: {
            "update:visible": function ($event) {
              _vm.deptShow = $event
            },
          },
        },
        [
          _c("DeptDialog", {
            ref: "DeptDialog",
            on: { "select-data": _vm.selectDept },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
          attrs: {
            title: "附件预览",
            modal: true,
            "modal-append-to-body": false,
            "close-on-click-modal": false,
            visible: _vm.showFileDialog,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showFileDialog = $event
            },
          },
        },
        [
          _c("iframe", {
            ref: "fileIframe",
            staticStyle: { width: "100%", height: "500px" },
            attrs: { src: _vm.fileUrl, frameborder: "0" },
          }),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.showFileDialog = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }